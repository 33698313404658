import React, { JSX, FC, ReactNode } from "react";

import css from "./style.module.css";

interface IInfoCardProps {
  text: string;
  title?: string;
  actionBtn?: ReactNode;
  className?: string;
}

const InfoCard: FC<IInfoCardProps> = ({
  text,
  title,
  actionBtn,
  className = ""
}): JSX.Element => {
  return (
    <div className={`flex justify-between items-top py-3 px-4 rounded-xl mb-4 ${css.wrapper} ${className}`}>
      <div className="mr-6">
        {title && <h5 className="text-base mb-1">{title}</h5>}
        <p className={`text-14-r ${css.text}`}>{text}</p>
      </div>
      {actionBtn}
    </div>
  );
};

export default InfoCard;
