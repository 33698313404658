import { FC } from "react";

import ImportExcel from "../ImportExcel";
import ImportYML from "../ImportYML";
import PagePanel from "../../../components/ui/Panel/PagePanel";

import { FormInstance } from "antd";

// TODO: Как будет ручной импорт заменить на значение из АПИ
export type ImportModeType = "yml" | "xlsx"; // | "manual";

interface IImportModePanelProps {
  form: FormInstance<any>;
  importMode: ImportModeType;
};

const ImportModePanel: FC<IImportModePanelProps> = ({ importMode, form }) => {
  const renderImportMode = () => {
    switch (importMode) {
      case "yml":
        return <ImportYML form={form} />;

      case "xlsx":
        return <ImportExcel form={form} />;

      // TODO: Как будет дизайн подключить компонент
      // case "manual":
      default:
        return <ImportYML form={form} />;
    }
  };

  return (
    <PagePanel>
      {renderImportMode()}
    </PagePanel>
  );
};

export default ImportModePanel;