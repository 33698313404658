import React, { JSX } from "react";
import dayjs from "dayjs";
import Column from "antd/es/table/Column";

export const renderUpdatedAtColumn = (renderColumnTitle: (title: string) => JSX.Element): JSX.Element => {
  return (
    <Column
      key="Date changes"
      title={renderColumnTitle("Последнее изменение")}
      dataIndex="updated_at"
      width={150}
      sorter={{ multiple: 3 }}
      sortDirections={["ascend", "descend"]}
      showSorterTooltip={false}
      render={(value: string) => <>{dayjs(value).format("DD.MM.YYYY HH:mm")}</>}
    />
  );
};
