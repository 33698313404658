import { FC } from "react";
import { useDispatch } from "react-redux";
import { createTempDocument } from "../../redux/thunks/queues.thunk";

import { UploadOutlined } from "@ant-design/icons";
import { Button, message, Upload } from "antd";

import { TempDocumentType } from "queues/types";
import { AppDispatch } from "../../redux/store/store";
import { RcFile } from "antd/es/upload";

interface IFileUploadButton {
  acceptType: string;
  description: string;
  setUploadedFileData: React.Dispatch<React.SetStateAction<TempDocumentType>>;
};

const FileUploadButton: FC<IFileUploadButton> = ({
  acceptType,
  description,
  setUploadedFileData
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const handleError = (messageText: string, error: any): void => {
    message.error(messageText);
    console.error(new Error(error));
  };

  const handleUploadFile = async (file: RcFile): Promise<void> => {
    // TODO: Доделать как будет апи
    await dispatch(createTempDocument(file))
      .then(setUploadedFileData)
      .catch((error: any) => handleError("Ошибка при загрузке документа", error));
  };

  const handleBeforeUpload = (file: RcFile, _fileList: RcFile[]): boolean => {
    handleUploadFile(file);

    return false;
  };

  return (
    <Upload
      beforeUpload={handleBeforeUpload}
      showUploadList
      className="flex flex-col"
      accept={acceptType}
    >
      <div className="flex items-center gap-4">
        <Button
          type="primary"
          icon={<UploadOutlined />}
          className="align-center"
          size="middle"
          ghost
        >
          Выбрать файл
        </Button>
        <p className="ghost">{description}</p>
      </div>
    </Upload>
  );
};

export default FileUploadButton;