import React, { JSX, FC, ReactNode, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MenuInfo } from "rc-menu/lib/interface";
import { Layout, Menu } from "antd";

// icons
import { ReactComponent as MainIcon } from "../../assets/icons/main-icon.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/edit-icon.svg";
import { ReactComponent as FileIcon } from "../../assets/icons/file-icon.svg";
import { ReactComponent as UserIcon } from "../../assets/icons/user-icon.svg";
import { ReactComponent as InterfaceIcon } from "../../assets/icons/interface-icon.svg";
import { ReactComponent as ReportsIcon } from "../../assets/icons/reports-icon.svg";
import { ReactComponent as WorkflowIcon } from "../../assets/icons/workflow-icon.svg";
import { ReactComponent as BriefcaseIcon } from "../../assets/icons/briefcase-icon.svg";
import { ReactComponent as HelpIcon } from "../../assets/icons/help-icon.svg";
import { ReactComponent as PhoneIcon } from "../../assets/icons/phone-icon.svg";
import { ReactComponent as EmailIcon } from "../../assets/icons/email-icon.svg";

import css from "./style.module.css";

interface MenuItemProps {
  key: string;
  icon: ReactNode;
  label: string | ReactNode;
  url?: string;
  isShow?: boolean;
  onClick?: () => void;
}

interface ISidebar {
  isVendor: boolean;
}

const Sidebar: FC<ISidebar> = ({isVendor}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [current, setCurrent] = useState<string>("");

  useEffect(() => {
    const currentMenu: string = MAIN_MENU_ITEMS
      .find((item) => item.url === location.pathname)?.key
      || "";

    setCurrent(currentMenu);
  }, [location]);

  const handleSelectItem = (e: MenuInfo): void => {
    setCurrent(e.key);
  };

  const renderMenuItem: FC<MenuItemProps> = ({
    key,
    icon,
    label,
    url,
    onClick
  }): JSX.Element => (
    <Menu.Item
      key={key}
      icon={icon}
      className={`${css.menu_item} ${key === current ? css.selected : ""}`}
      onClick={() => {
        onClick && onClick();
        url && navigate(url);
      }}
    >
      {label}
    </Menu.Item >
  );

  const MAIN_MENU_ITEMS: MenuItemProps[] = [
    {
      key: "route-main",
      icon: <MainIcon className={css.menu_icon} />,
      label: "Главная",
      url: isVendor ? "/platform/home" : "/",
      isShow: true,
    },
    {
      key: "route-trades",
      icon: <EditIcon className={css.menu_icon} />,
      label: "Запросы",
      url: "/trades",
      isShow: !isVendor,
    },
    {
      key: "route-catalog",
      icon: <FileIcon className={css.menu_icon} />,
      label: "Каталог",
      url: "/new/platform/catalog",
      isShow: true,
    },
    {
      key: "route-suppliers",
      icon: <UserIcon className={css.menu_icon} />,
      label: "Поставщики",
      url: "/new/platform/suppliers",
      isShow: true,
    },
    {
      key: "route-orders",
      icon: <InterfaceIcon className={css.menu_icon} />,
      label: "Заказы",
      url: "/orders",
      isShow: true,
    },
    {
      key: "route-import",
      icon: <FileIcon className={css.menu_icon} />,
      label: "Мой каталог",
      url: "/import",
      isShow: true,
    },
    {
      key: "route-reports",
      icon: <ReportsIcon className={css.menu_icon} />,
      label: "Отчеты",
      url: "/reports",
      isShow: true,
    },
    {
      key: "route-workflow",
      icon: <WorkflowIcon className={css.menu_icon} />,
      label: "Документооборот",
      url: "/workflow",
      isShow: !isVendor,
    },
    {
      key: "route-smart-business",
      icon: <BriefcaseIcon className={css.menu_icon} />,
      label: "Умный бизнес",
      url: "/new/platform/smart-business",
      isShow: !isVendor,
    },
  ];

  const HELP_MENU_ITEMS: MenuItemProps[] = [
    {
      key: "route-help",
      icon: <HelpIcon className={css.menu_icon} />,
      label: "Справка",
      url: "/help/main_ru",
    },
    {
      key: "phone",
      icon: <PhoneIcon className={css.menu_icon} />,
      label: "8(800)222-42-14",
      onClick: () => window.location.href = "tel:88002224214",
    },
    {
      key: "email",
      icon: <EmailIcon className={css.menu_icon} />,
      label: "help@vtbconnect.ru",
      onClick: () => window.location.href = "mailto:help@vtbconnect.ru",
    },
  ];

  const filteredMenuItems: MenuItemProps[] = MAIN_MENU_ITEMS.filter(({isShow}) => isShow);

  const renderMenu = (menuItems: MenuItemProps[], title?: string): JSX.Element => (
    <Menu
      theme="light"
      mode="inline"
      defaultSelectedKeys={[current]}
      style={{ borderInlineEnd: "none" }}
      className={css.sidebar_menu}
      selectedKeys={[current]}
      onClick={handleSelectItem}
    >
      {title && <p className="font-bold main-font-color mt-5 mb-6 ml-8">{title}</p>}
      {menuItems.map(renderMenuItem)}
    </Menu>
  );

  return (
    <Layout.Sider
      theme="light"
      className={css.left_sidebar}
      width="300px"
      style={{ position: "sticky", border: "none" }}
    >
      <div className="w-[256px]">
        {renderMenu(filteredMenuItems)}
        {renderMenu(HELP_MENU_ITEMS, "Помощь")}
      </div>
    </Layout.Sider>
  );
};

export default Sidebar;
