export const APP_PATHES = {
    GENERAL: "/",
    TRADES: "/trades",
    CATALOG: "/new/platform/catalog",
    SUPPLIERS: "/new/platform/suppliers",
    ORDERS: "/orders",
    IMPORT: "/import",
    IMPORT_MASTER: "/import-master",
    REPORTS: "/reports",
    WORKFLOW: "/new/platform/smart-business",
};

