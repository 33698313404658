import React, { FC, JSX, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router";
import { NavigateFunction, useNavigate } from "react-router-dom";

// components
import MainLayout from "./components/MainLayout/MainLayout";
import Catalog from "./pages/Catalog";
import ImportMaster from "./pages/ImportMaster";

const App: FC = (): JSX.Element => {
  const token: string = localStorage.getItem("authtoken");

  const navigate: NavigateFunction = useNavigate();
  const params = useLocation();

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, [navigate, token, params.pathname]);

  return (
    <MainLayout>
      {token && (
        <Routes>
          <Route path="/import/:tab?" element={<Catalog />} />
          <Route path="/import-master/" element={<ImportMaster />} />
        </Routes>
      )}
    </MainLayout>
  );
};

export default App;
