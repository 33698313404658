import React, { JSX, FC, useState, Key, useEffect } from "react";
import { TablePaginationConfig, TableRowSelection } from "antd/es/table/interface";
import {
  MyCatalogProductType,
  PriceType,
  RequestMyCatalogFiltersType,
} from "app/types";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../../redux/reducers/mainReducer";
import { InitialMyCatalogStateType } from "../../../redux/slices/myCatalogSlice";
import { AppDispatch } from "../../../redux/store/store";
import { fetchMyCatalogList } from "../../../redux/thunks/myCatalog.thunk";

//components
import { Dropdown, FormInstance, MenuProps, Select } from "antd";
import GhostButton from "../../../ui/GhostButton/GhostButton";
import CatalogTable from "../CatalogTable/CatalogTable";
import SearchFilterPanel from "../SearchFilterPanel/SearchFilterPanel";
import ProductsTableStub from "../ProductsTableStub/ProductsTableStub";

// icons
import { ReactComponent as MenuIcon } from "../../../assets/icons/three_dots_icon.svg";

import css from "./style.module.css";

interface IUploadedProductsTabProps {
  form: FormInstance;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
}

const options = [
  { label: "Портал Бизнес Коннект", value: 0 },
  { label: "Вендор Менеджмент", value: 1 }
];

// TODO: моки удалить после появления ручек
const myCatalogMocks: MyCatalogProductType[] = [
  {
  "id": "5736528",
  "photo": "http://dev.www.smbconnect.ru/media/products/5763699/5736528.PT01.png",
  "name": "Третья еда сбер",
  "supplier_category": "Доставка еды",
  "category_bk": "Доставка еды",
  "price": 999.0,
  "currency": "RUB",
  "updated_at": "2023-01-01 12:00",
  "source": "YML",
  "status": "published"
  },
  {
    "id": "5736527",
    "photo": "http://dev.www.smbconnect.ru/media/products/5763698/5736527.PT01.png",
    "name": "Вторая еда",
    "supplier_category": "Доставка еды",
    "category_bk": "Доставка еды",
    "price": 200.0,
    "currency": "RUB",
    "updated_at": "2024-02-29 09:00",
    "source": "YML",
    "status": "moderation"
  },
];
// TODO: моки удалить после появления ручек
const priceTypeListMocks: PriceType[] = [
  { "id": 122, "name": "Первый тип" },
  { "id": 123, "name": "Второй тип" },
  { "id": 124, "name": "Третий тип" }
];

const UploadedProductsTab: FC<IUploadedProductsTabProps> = ({setActiveTab}): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();

  const myCatalog: InitialMyCatalogStateType = useSelector((state: AppStateType) => state.myCatalogSlice);

  const [selectedRows, setSelectedRows] = useState<MyCatalogProductType[]>(null);
  const [selectedKeys, setSelectedKeys] = useState<Key[]>(null);
  const [paginationInfo, setPaginationInfo] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
    locale: {
      items_per_page: "/ на странице",
    },
  });

  useEffect(() => {
    setSelectedKeys(selectedRows?.map((value) => value.key));
  }, [selectedRows]);

  // Логика работы с чекбоксами у таблицы
  const rowSelection: TableRowSelection<any> = {
    selectedRowKeys: selectedKeys,
    onChange: (selectedRowKeys, selectedRows: MyCatalogProductType[]) => {
      setSelectedRows(selectedRows);
      setSelectedKeys(selectedRowKeys);
    }
  };

  const fetchProductsList = async (params: RequestMyCatalogFiltersType): Promise<void> => {
    await dispatch(fetchMyCatalogList(params));
  };

  const renderFiltersPanel = () => {
    return (
      <div>
        <div className="flex">
          <Select
            size="large"
            options={options}
            className={`mr-3 ${css.select}`}
            defaultValue={options[0].value}
          />
          <SearchFilterPanel
            placeholder="Найти по наименованию товара"
            onSearch={(value) => console.log(value)}
          />
        </div>
        <div className="flex">
          {!!selectedRows?.length && (
            <GhostButton
              className="mr-3"
              text="Удалить выбранные"
              onClickHandler={() =>{}}
            />
          )}
          <Select placeholder="По категории" className="mr-3" />
          <Select
            placeholder="По типу цены"
            allowClear
            options={priceTypeListMocks?.map(({id, name}) => ({
              value: id,
              label: name
            }))}
          />
        </div>
      </div>
    );
  };

  const renderProductActions = (_value: any, idx: number): React.ReactNode => {
    const items: MenuProps["items"] = [
      {
        label: <a onClick={() => {}}>Редактировать</a>,
        key: `edit-${idx}`,
        disabled: true
      },
      {
        label: <a onClick={() => {}}>Создать копию</a>,
        key: `copy-${idx}`,
        disabled: true
      },
      {
        label: <a onClick={() => {}}>Редактировать цену</a>,
        key: `edit-price-${idx}`,
        disabled: true
      },
      {
        label: <a onClick={() => {}} style={{color: "#FF4D4F"}}>Удалить</a>,
        key: `delete-${idx}`,
        disabled: true
      },
    ];

    return (
      <Dropdown menu={{ items }} trigger={["click"]}>
        <MenuIcon className="m-auto cursor-pointer secondary-color" />
      </Dropdown>
    );
  };

  return (
    <div>
      {renderFiltersPanel()}
      <CatalogTable
        columns={[
          "photo",
          "name",
          "category",
          "bk_category",
          "price",
          "updated_at",
          "download_source",
          "status",
          "actions"
        ]}
        list={myCatalog.list ?? myCatalogMocks}
        count={myCatalog.total}
        isShowEmptyBlock
        rowSelection={rowSelection}
        emptyBlock={<ProductsTableStub setActiveTab={setActiveTab}/>}
        fetchList={fetchProductsList}
        renderActions={renderProductActions}
        setPaginationInfo={setPaginationInfo}
      />
    </div>
  );
};

export default UploadedProductsTab;
