import React, { JSX } from "react";
import dayjs from "dayjs";
import Column from "antd/es/table/Column";

export const renderDateTimeColumn = (renderColumnTitle: (title: string) => JSX.Element): JSX.Element => {
  return (
    <Column
      key="Date_time"
      title={renderColumnTitle("Дата и время")}
      dataIndex="created_at"
      width={150}
      sorter={{ multiple: 3 }}
      showSorterTooltip={false}
      sortDirections={["ascend", "descend"]}
      render={(value: string) => {
        return (
          <>{dayjs(value).format("DD.MM.YYYY HH:mm")}</>
        );
      }}
    />
  );
};
