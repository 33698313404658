import { FC } from "react";

interface IPanelBlockProps {
  title?: string;
  titleClassName?: string;
  className?: string;
  children: JSX.Element | JSX.Element[];
};

const PanelBlock: FC<IPanelBlockProps> = ({ title, titleClassName, className = "", children }) => (
  <div className="w-full flex flex-col grow-1 md:w-0 md:grow-1/2 xl:grow-1/3">
    {title && (
      <div className="header_wrapper">
        <p className={titleClassName || "header_text"}>{title}</p>
      </div>
    )}
    <div className={`content_wrapper flex flex-col ${className}`}>
      {children}
    </div>
  </div>
);

export default PanelBlock;