import React, { JSX, ReactNode } from "react";

import { UploadEntityType } from "../CatalogTable";
import { UploadAutoType } from "app/types";

import Column from "antd/es/table/Column";

export const renderActionsColumn = (renderActions: (entity: any, idx: number) => ReactNode): JSX.Element => {
  return (
    <Column
      key="Action"
      title=""
      dataIndex="action"
      width={100}
      fixed="right"
      render={(
        _value: string,
        entity: UploadEntityType,
        index: number
      ) => renderActions((entity as UploadAutoType), index)}
    />
  );
};
