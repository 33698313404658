import axios from "../utils/axios";
import { AxiosResponse } from "axios";
import {
  PostImportTaskRequestType,
  GetImportTasksRequestType,
  ImportTaskResponseType,
  ImportTasksResponseType,
  TempDocumentParseType,
} from "queues/parseTypes";
import { UploadFile } from "antd/lib/upload/interface";

const API_BASE: string | undefined = process.env.REACT_APP_API_BASE_URL?.trimEnd();

{/**** Импорт из файла ****/}

/** Создание задания на импорт из файла */
export const postImportTask = (body: PostImportTaskRequestType): Promise<AxiosResponse<ImportTaskResponseType>> => {
  return axios.POST(API_BASE, "/queues/import_task", body);
};

/** Получение задания на импорт из файла */
export const getImportTask = (taskId: string): Promise<AxiosResponse<ImportTaskResponseType>> => {
  return axios.GET(API_BASE, `/queues/import_task/${taskId}`);
};

/** Получение списка заданий на импорт из файла */
export const getImportTasks = (body: GetImportTasksRequestType): Promise<AxiosResponse<ImportTasksResponseType>> => {
  return axios.GET(API_BASE, `/queues/import_task/${body}`);
};

{/**** Временный документ ****/}

/** Создание временного документа */
export const postTempDocument = (file: File | UploadFile): Promise<AxiosResponse<TempDocumentParseType>> => {
  return axios.POST(API_BASE, `/queues/temp_documents`, file);
};

/** Получение временного документа */
export const getTempDocument = (tempDocumentId: string): Promise<AxiosResponse<TempDocumentParseType>> => {
  return axios.GET(API_BASE, `/queues/temp_documents/${tempDocumentId}`);
};