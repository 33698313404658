export const toCamelCase = (snakeCaseObj: any): any => {
  if (Array.isArray(snakeCaseObj)) {
    return snakeCaseObj.map((item) => toCamelCase(item));
  } else if (snakeCaseObj !== null && typeof snakeCaseObj === "object") {
    const camelCaseObj: Record<string, any> = {};

    for (const key in snakeCaseObj) {
      if (Object.hasOwn(snakeCaseObj, key)) {
        const camelCaseKey: string = key.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());
        camelCaseObj[camelCaseKey] = toCamelCase(snakeCaseObj[key]);
      }
    }
    return camelCaseObj;
  } else {
    return snakeCaseObj;
  }
};