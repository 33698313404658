import React, { JSX } from "react";
import { UploadAutoType, UploadManualType } from "app/types";
import Column from "antd/es/table/Column";

export const renderFileColumn = (renderColumnTitle: (title: string) => JSX.Element): JSX.Element => {
  const getFile = (entity: UploadManualType | UploadAutoType): { fileName: string, link: string } => {
    const uploadManualFile: Record<string, string> = (entity as UploadManualType)?.file;

    return {
      link: uploadManualFile
        ? uploadManualFile?.url
        : (entity as UploadAutoType)?.link,
      fileName: uploadManualFile
        ? uploadManualFile?.file_name
        : (entity as UploadAutoType)?.link
    };
  };

  return (
    <Column
      key="File"
      title={renderColumnTitle("Файл")}
      dataIndex="file"
      width={300}
      render={(_value, entity: UploadManualType | UploadAutoType) => (
        <a
          href={getFile(entity).link}
          target="_blank"
          rel="noreferrer"
          className="blue-color"
        >
          {getFile(entity).fileName}
        </a>
      )}
    />
  );
};
