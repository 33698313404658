import React, { JSX } from "react";

import Column from "antd/es/table/Column";
import TextWithTooltip from "../../../../ui/TextWithTooltip/TextWithTooltip";

export const renderProductsCountColumn = (renderColumnTitle: (title: string) => JSX.Element): JSX.Element => {
  return (
    <Column
      key="Products_count"
      title={renderColumnTitle("Товаров в категории")}
      dataIndex="products_count" // TODO поменять на поле из апи, когда оно появится
      width={100}
      sorter={{ multiple: 3 }}
      showSorterTooltip={false}
      sortDirections={["ascend", "descend"]}
      // TODO: когда появится описание АПИ поменять на реальные данные
      render={(value: string) => value ? <TextWithTooltip text={value} /> : <span>-</span>}
    />
  );
};
