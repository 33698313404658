import React, { JSX, FC, Dispatch } from "react";
import { APP_PATHES } from "../../../constants/appPathes";
import { NavigateFunction, useNavigate } from "react-router";
import { Tab } from "../TabsWithCounters/TabsWithCounters";

// components
import PrimaryButton from "../../../ui/PrimaryButton/PrimaryButton";
import GhostButton from "../../../ui/GhostButton/GhostButton";
import EmptyBlock from "../../EmptyBlock/EmptyBlock";

// icons
import { ReactComponent as LoupeIcon } from "../../../assets/icons/search-square-icon.svg";

interface IProductsTableStubProps {
  setActiveTab: Dispatch<React.SetStateAction<string>>;
}

const ProductsTableStub: FC<IProductsTableStubProps> = ({setActiveTab}): JSX.Element => {
  const navigate: NavigateFunction = useNavigate();

  const handleNavigate = (): void => navigate(APP_PATHES.IMPORT_MASTER);

  return (
    <EmptyBlock
      icon={<LoupeIcon className="mb-4" />}
      style={{ height: "calc(100vh - 23rem)" }}
      title="Товаров и категорий нет"
      description={
        <>
          Для добавления товаров перейдите в раздел
          <br/>
          <span
            className="blue-color cursor-pointer"
            onClick={() => setActiveTab(Tab.uploading)}
          >
            Загрузка товаров
          </span>. Условия для поставщиков
          <br/>
          можно посмотреть в <a href="#" className="blue-color hover:blue-color">КБО</a>
        </>
      }
      btn={
        <div className="flex mb-4">
          <PrimaryButton text="Загрузить" onClickHandler={handleNavigate}/>
          <GhostButton
            size="large"
            text="Инструкция"
            className="ml-3"
            onClickHandler={() => {}}
          />
        </div>
      }
      hint="Размещение товаров в каталоге — Бесплатно"
    />
  );
};

export default ProductsTableStub;
