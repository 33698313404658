import React, { JSX } from "react";
import Column from "antd/es/table/Column";
import TextWithTooltip from "../../../../ui/TextWithTooltip/TextWithTooltip";

export const renderEditableBKCategoryColumn = (renderColumnTitle: (title: string) => JSX.Element): JSX.Element => {
  return (
    <Column
      key="Editable_bk_category"
      title={renderColumnTitle("Категория Бизнес Коннект")}
      dataIndex="category_bk" // TODO поменять на поле из апи, когда оно появится
      width={400}
      sorter={{ multiple: 3 }}
      showSorterTooltip={false}
      sortDirections={["ascend", "descend"]}
      // TODO: когда появится структура апи поменять на актуальные данные
      render={(value: string) => value ? <TextWithTooltip text={value} /> : <span>-</span>}
    />
  );
};
