import React, { JSX } from "react";
import Column from "antd/es/table/Column";
import { Tag } from "antd";

export const renderDownloadSourceColumn = (renderColumnTitle: (title: string) => JSX.Element): JSX.Element => {
  return (
    <Column
      key="Download_source"
      title={renderColumnTitle("Источник загрузки")}
      dataIndex="source"
      width={150}
      sorter={{ multiple: 3 }}
      sortDirections={["ascend", "descend"]}
      showSorterTooltip={false}
      render={(value: string) => {
        const source: string = value === "manual" ? "Ручной" : value?.toUpperCase();

        return <Tag color="default">{source}</Tag>;
      }}
    />
  );
};
