import React, { JSX, FC, Dispatch } from "react";
import { FormInstance } from "antd";

import { Tab } from "../TabsWithCounters/TabsWithCounters";
import UploadedProductsTab from "../UploadedProductsTab/UploadedProductsTab";
import UploadingProductsTab from "../UploadingProductsTab/UploadingProductsTab";
import ComparisonOfCategoriesTab from "../ComparisonOfCategoriesTab/ComparisonOfCategoriesTab";

interface ITabWrapperProps {
  form: FormInstance;
  currentTab?: string;
  setActiveTab?: Dispatch<React.SetStateAction<string>>;
}

const TabWrapper: FC<ITabWrapperProps> = ({form, currentTab, setActiveTab}): JSX.Element => {
  const renderCurrentTab = (): JSX.Element => {
    switch (currentTab) {
      case Tab.uploading: {
        return <UploadingProductsTab form={form} />;
      }
      case Tab.comparison: {
        return <ComparisonOfCategoriesTab setActiveTab={setActiveTab} />;
      }
      case Tab.comments: {
        return <></>; // TODO: тут будет страница "Комментарии от модератора"
      }
      default: return <UploadedProductsTab form={form} setActiveTab={setActiveTab} />;
    }
  };

  return (
    renderCurrentTab()
  );
};

export default TabWrapper;
