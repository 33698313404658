import React, { FC } from "react";

import css from "./style.module.css";

interface IPanelLayoutProps {
  title?: React.ReactNode;
  description?: React.ReactNode;
  className?: string;
  children?: React.ReactNode;
}

const PanelLayout: FC<IPanelLayoutProps> = ({
  title,
  description,
  className = "",
  children,
}) => {
  return (
    <div className="panel_layout flex flex-col gap-4">
      {(title || description) && (
        <div className="header_wrapper">
          {title && (
            <div className="title_wrapper">
              <h1 className={css.title_text}>{title}</h1>
            </div>
          )}
          {description && (
            <div className="description_wrapper">
              <p className={`${css.description_text} ghost`}>
                {description}
              </p>
            </div>
          )}
        </div>)}
      <div className={`content_wrapper ${className}`}>
        {children}
      </div>
    </div>
  );
};

export default PanelLayout;