import { combineReducers } from "redux";
import myCatalogSlice from "../slices/myCatalogSlice";
import uploadsSlice from "../slices/uploadsSlice";
import moderatorCommentsSlice from "../slices/moderatorCommentsSlice";
import queuesSlice from "../slices/queuesSlice";
import tempDocumentsSlice from "../slices/tempDocumentsSlice";

const mainReducer = combineReducers({
  myCatalogSlice,
  uploadsSlice,
  moderatorCommentsSlice,
  queuesSlice,
  tempDocumentsSlice,
});

type MainReducerType = typeof mainReducer;
export type AppStateType = ReturnType<MainReducerType>;

export default mainReducer;
