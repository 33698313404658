import React, { JSX } from "react";
import dayjs from "dayjs";
import Column from "antd/es/table/Column";

export const renderCreatedAtColumn = (renderColumnTitle: (title: string) => JSX.Element): JSX.Element => {
  return (
    <Column
      key="created_at"
      title={renderColumnTitle("Дата создания")}
      dataIndex="created_at"
      sorter={{ multiple: 3 }}
      showSorterTooltip={false}
      className="select-none"
      width={150}
      sortDirections={["ascend", "descend"]}
      render={(value: string) => {
        return (
          <>{dayjs(value)?.format("DD.MM.YYYY HH:mm")}</>
        );
      }}
    />
  );
};
