import React, { JSX } from "react";
import Column from "antd/es/table/Column";
import ImageContainer from "../../../../ui/ImageContainer/ImageContainer";

import css from "../style.module.css";

export const renderPhotoColumn = (renderColumnTitle: (title: string) => JSX.Element): JSX.Element => {
  return (
    <Column
      key="Photo"
      title={renderColumnTitle("Фото")}
      dataIndex="photo"
      width={65}
      fixed="left"
      className={css.cellPhoto}
      render={(image: string) => (
        <div className={css.photoWrapper}>
          <ImageContainer
            src={image}
            alt="Фото"
            imageContainerClass={css.photo}
            onClick={() => {}} // TODO: здесь будет навигация на форму редактирования продукта
          />
        </div>
      )}
    />
  );
};
