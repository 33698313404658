import React, { JSX, FC, useState } from "react";

import { logout } from "../../../redux/actions/account.actions";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store/store";

import { ReactComponent as ArrowDownIcon } from "../../../assets/icons/arrow-down-icon.svg";
import { Dropdown, MenuProps } from "antd";
import { Link } from "react-router-dom";

import css from "./style.module.css";

interface IHeaderUserInfoProps {}

// TODO: когда будут данные, подставить актуальные
const HeaderUserInfo: FC<IHeaderUserInfoProps> = (): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();

  const [isShow, setIsShow] = useState<boolean>(false);

  const items: MenuProps["items"] = [
    {
      label: <Link to="/settings">Настройки</Link>,
      key: 0,
    },
    {
      label: <span onClick={() => dispatch(logout())}>Выйти</span>,
      key: 1
    }
  ];

  return (
    <Dropdown menu={{items}} trigger={["click"]}>
      <div
        className={`flex items-center cursor-pointer ${css.userInfoContainer}`}
        onClick={() => setIsShow(!isShow)}
      >
        <div className="flex flex-col items-start px-4">
          <span className="light-gray-color text-start">test@test.ru</span>
          <span className="font-bold text-start text-white">Тестовый поставщик</span>
        </div>
        <ArrowDownIcon className={`${isShow ? css.rotate : ""} ${css.arrow}`}/>
      </div>
    </Dropdown>
  );
};

export default HeaderUserInfo;
