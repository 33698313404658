import React, { JSX } from "react";
import Column from "antd/es/table/Column";
import { Tag } from "antd";

export const renderFileTypeColumn = (renderColumnTitle: (title: string) => JSX.Element): JSX.Element => {
  return (
    <Column
      key="File_type"
      title={renderColumnTitle("Тип файла")}
      dataIndex="file_type"
      width={100}
      sorter={{ multiple: 3 }}
      showSorterTooltip={false}
      sortDirections={["ascend", "descend"]}
      render={(_value, _entity) => {
        // TODO: добавить реальные данные
        return (
          <Tag color="default">YML</Tag>
        );
      }}
    />
  );
};
