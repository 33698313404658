import * as QueuesAPI from "../../api/queues";
import { ThunkAction } from "redux-thunk";
import { toCamelCase } from "./../../utils/formatData";
import {
  createImportTaskRequest,
  createImportTaskSuccess,
  createImportTaskFailure,

  fetchImportTaskRequest,
  fetchImportTaskSuccess,
  fetchImportTaskFailure,

  fetchImportTasksRequest,
  fetchImportTasksSuccess,
  fetchImportTasksFailure,
} from "../slices/queuesSlice";

import {
  createTempDocumentRequest,
  createTempDocumentSuccess,
  createTempDocumentFailure,

  fetchTempDocumentRequest,
  fetchTempDocumentSuccess,
  fetchTempDocumentFailure,
} from "../slices/tempDocumentsSlice";

import { UploadFile } from "antd";
import {
  GetImportTasksRequestType,
  PostImportTaskRequestType,
} from "queues/parseTypes";
import { TempDocumentType } from "queues/types";
import { AppStateType } from "../reducers/mainReducer";

{/**** Задания на импорти ****/ }

/** Создание задания на импорт из файла */
export const createImportTask = (params: PostImportTaskRequestType): ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  any
> => async (dispatch) => {
  dispatch(createImportTaskRequest(params));
  try {
    const response = await QueuesAPI.postImportTask(params);

    dispatch(createImportTaskSuccess(response?.data));
  } catch (error: any) {
    dispatch(createImportTaskFailure(error?.response?.data?.error));
  }
};

/** Получение задания на импорт из файла */
export const fetchImportTask = (taskId: string): ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  any
> => async (dispatch) => {
  dispatch(fetchImportTaskRequest(taskId));
  try {
    const response = await QueuesAPI.getImportTask(taskId);

    dispatch(fetchImportTaskSuccess(response?.data));
  } catch (error: any) {
    dispatch(fetchImportTaskFailure(error?.response?.data?.error));
  }
};

/** Получение списка заданий на импорт из файла */
export const fetchImportTasks = (body: GetImportTasksRequestType): ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  any
> => async (dispatch) => {
  dispatch(fetchImportTasksRequest(body));
  try {
    const response = await QueuesAPI.getImportTasks(body);

    dispatch(fetchImportTasksSuccess(response?.data));
  } catch (error: any) {
    dispatch(fetchImportTasksFailure(error?.response?.data?.error));
  }
};

{/**** Временный документ ****/ }

/** Получение временного документа */
export const fetchTempDocument = (documentId: string): ThunkAction<
  Promise<TempDocumentType>,
  AppStateType,
  undefined,
  any
> => async (dispatch) => {
  dispatch(fetchTempDocumentRequest(documentId));
  try {
    const response = await QueuesAPI.getTempDocument(documentId);
    const formattedResponse: TempDocumentType = toCamelCase(response?.data);

    dispatch(fetchTempDocumentSuccess(formattedResponse));

    return Promise.resolve(formattedResponse);
  } catch (error: any) {
    dispatch(fetchTempDocumentFailure(error));

    return Promise.reject(error);
  }
};

/** Создание временного документа */
export const createTempDocument = (file: File | UploadFile): ThunkAction<
  Promise<TempDocumentType>,
  AppStateType,
  undefined,
  any
> => async (dispatch) => {
  dispatch(createTempDocumentRequest(file));
  try {
    const response = await QueuesAPI.postTempDocument(file);
    const formattedResponse: TempDocumentType = toCamelCase(response?.data);

    dispatch(createTempDocumentSuccess(formattedResponse));

    return Promise.resolve(formattedResponse);
  } catch (error: any) {
    dispatch(createTempDocumentFailure(error));

    return Promise.reject(error);
  }
};
