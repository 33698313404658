import React, { JSX } from "react";

import Column from "antd/es/table/Column";
import TextWithTooltip from "../../../../ui/TextWithTooltip/TextWithTooltip";

export const renderNameColumn = (renderColumnTitle: (title: string) => JSX.Element): JSX.Element => {
  return (
    <Column
      key="Name"
      title={renderColumnTitle("Наименование товара")}
      dataIndex="name"
      width={200}
      sorter={{ multiple: 3 }}
      showSorterTooltip={false}
      sortDirections={["ascend", "descend"]}
      render={(text: string) => {
        return (
          <a
            className="flex"
            onClick={() => {}} // TODO: здесь будет навигация на форму редактирования продукта
          >
            <TextWithTooltip text={text} />
          </a>
        );
      }}
    />
  );
};
