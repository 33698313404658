import React, { JSX } from "react";

import Column from "antd/es/table/Column";
import TextWithTooltip from "../../../../ui/TextWithTooltip/TextWithTooltip";

export const renderBKCategoryColumn = (renderColumnTitle: (title: string) => JSX.Element): JSX.Element => {
  return (
    <Column
      key="Bk_category"
      title={renderColumnTitle("Категория БК")}
      dataIndex="category_bk"
      width={200}
      sorter={{ multiple: 3 }}
      showSorterTooltip={false}
      sortDirections={["ascend", "descend"]}
      render={(value: string) => value ? <TextWithTooltip text={value} /> : <span>-</span>}
    />
  );
};

