import React, { JSX } from "react";

import Column from "antd/es/table/Column";
import TextWithTooltip from "../../../../ui/TextWithTooltip/TextWithTooltip";

export const renderCategoryColumn = (renderColumnTitle: (title: string) => JSX.Element): JSX.Element => {
  return (
    <Column
      key="Category"
      title={renderColumnTitle("Категория")}
      dataIndex="supplier_category"
      width={200}
      sorter={{ multiple: 3 }}
      showSorterTooltip={false}
      sortDirections={["ascend", "descend"]}
      render={(value: string) => <TextWithTooltip text={value} />}
    />
  );
};
