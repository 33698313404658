import { createSlice } from "@reduxjs/toolkit";
import { ImportTaskResponseType } from "queues/types";

export type InitialQueuesStateType = {
  total: number;
  page: number;
  pageSize: number;
  list: ImportTaskResponseType[] | null;
  error: string | null;
  isFetching: boolean;
};

const initialState: InitialQueuesStateType = {
  total: 0,
  page: 1,
  pageSize: 10,
  list: null,
  error: null,
  isFetching: false,
};

const queuesSlice = createSlice({
  name: "queues",
  initialState,
  reducers: {
    /** создание задания на импорт из файла */
    createImportTaskRequest(state, _action) {
      state.error = null;
      state.isFetching = true;
    },
    createImportTaskSuccess(state, action) {
      state.error = null;
      state.isFetching = false;
      state.list = [...state.list, action.payload];
    },
    createImportTaskFailure(state, _action) {
      state.error = null;
      state.isFetching = false;
    },

    /** Получение временного документа */
    fetchImportTaskRequest(state, _action) {
      state.error = null;
      state.isFetching = true;
    },
    fetchImportTaskSuccess(state, _action) {
      state.error = null;
      state.isFetching = false;
    },
    fetchImportTaskFailure(state, _action) {
      state.error = null;
      state.isFetching = false;
    },

    /** Получение списка заданий на импорт из файла */
    fetchImportTasksRequest(state, _action) {
      state.error = null;
      state.isFetching = true;
    },
    fetchImportTasksSuccess(state, action) {
      state.error = null;
      state.isFetching = false;
      state.total = action.payload.total;
      state.page = action.payload.page;
      state.pageSize = action.payload.pageSize;
    },
    fetchImportTasksFailure(state, _action) {
      state.error = null;
      state.isFetching = false;
    },
  },
});

const { actions, reducer } = queuesSlice;

export const {
  createImportTaskRequest,
  createImportTaskSuccess,
  createImportTaskFailure,

  fetchImportTaskRequest,
  fetchImportTaskSuccess,
  fetchImportTaskFailure,

  fetchImportTasksRequest,
  fetchImportTasksSuccess,
  fetchImportTasksFailure,
} = actions;

export default reducer;
