import React, { JSX } from "react";
import Column from "antd/es/table/Column";
import TextWithTooltip from "../../../../ui/TextWithTooltip/TextWithTooltip";

export const renderSupplierCategoryColumn = (renderColumnTitle: (title: string) => JSX.Element): JSX.Element => {
  return (
    <Column
      key="Supplier_category"
      title={renderColumnTitle("Категория поставщика")}
      dataIndex="supplier_category" // TODO поменять на поле из апи, когда оно появится
      width={400}
      sorter={{ multiple: 3 }}
      showSorterTooltip={false}
      sortDirections={["ascend", "descend"]}
      // TODO: когда появится описание АПИ добавить реальные данные
      render={(value: string) => value ? <TextWithTooltip text={value} /> : <span>-</span>}
    />
  );
};
