import React, { FC, useState, useEffect, useMemo, useCallback } from "react";
import { useLocation } from "react-router";
import { postImportTask } from "api/queues";

import PagePanel from "../../components/ui/Panel/PagePanel";
import PanelBlock from "../../components/ui/Panel/PanelBlock";
import ImportModePanel, { ImportModeType } from "./ImportModePanel";
import { SETTINGS_TYPE } from "./ImportYML";

import { Spin, Select, Radio, message, Tooltip, Button, Form, RadioChangeEvent } from "antd";

import css from "./style.module.css";

import { ImportTaskUploadSettingsType, PostImportTaskRequestType } from "queues/parseTypes";

type WarehouseType = {
  id: number;
  name: string;
};

type FormType = {
  warehouse: string;
  source?: ImportModeType;
  settings?: string[];
  fileField?: any;
  fileLink?: string;
  name?: string;
  login?: string;
  password?: string;
};

interface IImportMaster { };

const ImportMaster: FC<IImportMaster> = () => {
  const location = useLocation();
  const [form] = Form.useForm<FormType>();
  const { getFieldsValue, setFieldValue } = form;

  const [importMode, setImportMode] = useState<ImportModeType>("yml");
  const [isLoadingWarehouse, setIsLoadingWarehouse] = useState<boolean>(false);
  const [warehouseList, setWarehouseList] = useState<WarehouseType[]>([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState<number | null>(null);

  const params: URLSearchParams = useMemo(() => new URLSearchParams(location.search), []);

  useEffect(() => {
    const mode: ImportModeType = params.get("mode") as ImportModeType;
    fetchWarehouses();

    if (mode) setImportMode(mode);
  }, [params]);

  const handleError = (messageText: string, error: any): void => {
    message.error(messageText);
    console.error(error);
  };

  const fetchWarehouses = (filters: any = {}): void => {
    setIsLoadingWarehouse(true);
    filters.ordering ||= "id";
    // TODO: Переделать как будет апи
    // getWarehouses(filters)
    //   .then(({ results }) => setWarehouseList(results))
    //   .catch((error) => {
    //     setWarehouseList([]);
    //     handleError("Ошибка при загрузке списка складов.", error);
    //   })
    //   .finally(() => setIsLoadingWarehouse(false));
  };

  const handleChangeImportMode = (e: RadioChangeEvent): void => {
    setImportMode(e.target.value);
  };

  const handleSelectWarehouse = (value: number): void => {
    setSelectedWarehouse(value);
  };

  const getUploadSettings = (): ImportTaskUploadSettingsType => {
    const { settings } = getFieldsValue();

    return {
      is_periodic: settings?.includes(SETTINGS_TYPE.isPeriodic),
      rests: settings?.includes(SETTINGS_TYPE.updateRest),
      prices: settings?.includes(SETTINGS_TYPE.updatePrice),
      attributes: settings?.includes(SETTINGS_TYPE.updateProperty),
      clear_existing: settings?.includes(SETTINGS_TYPE.clearProducts),
      first_line_skip: settings?.includes("first_line_skip"),
      authorization: settings?.includes(SETTINGS_TYPE.logIn) ? {
        login: form.getFieldValue("login") ?? "",
        password: form.getFieldValue("password") ?? "",
      } : null,
    };
  };

  const getRequestBody = (): PostImportTaskRequestType => {
    const { ...values } = getFieldsValue();
    const isPeriodic: boolean = !!getUploadSettings()?.is_periodic;

    const isFromFile: boolean = !isPeriodic;

    const requestBody: PostImportTaskRequestType = {
      source: importMode,
      warehouse_id: selectedWarehouse?.toString(),
      settings: getUploadSettings(),
      file_link: values?.fileLink || "",
    };

    if (isFromFile) {
      requestBody.name = values?.name;
      requestBody.temp_file_id = "";
    }

    return requestBody;
  };

  const handleCreateTask = (): void => {
    const isPeriodic: boolean = !!getUploadSettings()?.is_periodic;

    const showMessage = (type: "error" | "success", error?: any): void => {
      const successMessage: string = isPeriodic ? "Периодическая задача" : "Задача";
      const errorMessage: string = isPeriodic ? "периодической задачи" : "задачи";

      if (type === "success") {
        message.success(`${successMessage} успешно создана`);
      } else {
        handleError(`Ошибка при создании ${errorMessage}`, error);
      }
    };

    postImportTask(getRequestBody())
      .then(() => showMessage("success"))
      .catch((error: any) => showMessage("error", error));
  };

  return (
    <div className={`${css.importMaster_wrapper} h-full p-4`}>
      {/* TODO: Как будет апи поменять логику на isLoadingWarehouse */}
      <Spin tip="Склады загружаются" size="default" spinning={!isLoadingWarehouse}>
        <PagePanel>
          <Form form={form} className="flex flex-col md:flex-row gap-4">
            <PanelBlock title="Выберите вариант импорта">
              <Radio.Group value={importMode} onChange={handleChangeImportMode}>
                <Tooltip title="Добавьте продукты, используя прейскурант в Excel (XLS, CSV)">
                  <Radio.Button value="xlsx">Import Excel</Radio.Button>
                </Tooltip>
                <Tooltip title="Публикация продуктов с использованием формата YML, Yandex.Market или Commerce-ML">
                  <Radio.Button value="yml">Import YML</Radio.Button>
                </Tooltip>
              </Radio.Group>
            </PanelBlock>

            <PanelBlock title="Выбор склада">
              <Form.Item name="warehouse" className="w-full m-0">
                <Select
                  placeholder="Список складов"
                  value={selectedWarehouse}
                  onSelect={handleSelectWarehouse}
                  disabled={!warehouseList.length}
                  notFoundContent={isLoadingWarehouse ? <Spin spinning={isLoadingWarehouse} /> : null}
                  className="w-full"
                >
                  {warehouseList.map(({ id, name }) => (
                    <Select.Option key={id} value={id}>
                      {name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </PanelBlock>
          </Form>
        </PagePanel>
      </Spin>

      {/* TODO: Как будет апи поменять логику на !selectedWarehouse */}
      <Spin tip="Выберите склад" size="default" spinning={!!selectedWarehouse}>
        <ImportModePanel form={form} importMode={importMode} />
      </Spin>

      <PagePanel className="apply_wrapper flex justify-end">
        <Button type="primary" size="large" onClick={handleCreateTask}>
          Загрузить каталог
        </Button>
      </PagePanel>
    </div>
  );
};

export default ImportMaster;